.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  display:initial;
}


.home-container {
  position: relative;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 250px;
  overflow: hidden;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  z-index: -1;
}

.App-title {
  font-size: 15px;
  font-weight: bold;
  padding-left: 15px;
  padding-bottom: 10px;
  color: rgb(0, 43, 97);
  position: relative;
  margin-top: 10px;
}

.background-gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to bottom, #d8b38d, #7c3f00);
  opacity: 0.65;
  z-index: 2;
}

.image-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  background-image: url("./Assets/feed.jpg");
}

.home-content {
  padding: 20px 40px;
  color: #fff;
}

.background-logo {
  margin: 20px 0px;
  width: 80px;
  height: auto;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  content: url("./Assets/nealia_feed_icon.png");
}

.home-title-user {
  font-size: 25px;
  text-align: center;
  color: #fff;
}

.buttons-container {
  margin: -20px;
  margin-top: 20px;
}

.home-button {
  color: #000;
  background-color: #fff;
  display: inline-block;
  cursor: pointer;

  height: 110px;
  width: calc(50vw - 80px);
  min-width: 80px;

  margin: 20px;
  margin-bottom: 15px;
  margin-top: 0px;
  border-radius: 15px;
  box-shadow: 5px 5px #88888860;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}

.feature-icon {
  margin-top: 5px;
  height: 60px;
  width: auto;
}

.button-text {
  margin-top: 15px;
  font-size: 12px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
