.dashboard-element {
    position: relative;
    /* margin: 0px;
    
    cursor: pointer;
    color: rgb(153, 153, 153); */
    /* border: 1px solid #cacaca; */
    margin: 0px;
    margin-top: -1px;
    border: 1px solid #cacaca55;
    /* box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.2); */
}

.dashboard-expansion-content {
    padding: 8px 10px 24px !important;
}

.dashboard-element-toggler {
    /* display: inline-block;
    font-size: 10px;
    margin: 0px 10px;
    vertical-align: middle; */
}

.dashboard-element-item {
    padding: 10px;
    display: inline-block;
}